export const environment = {
  production: true,
  title: 'iClinic Pay - Organização',
  name: 'prod',
  zoop_gateway_id: '90ba19c4-e347-4126-b797-74c3b5626622',
  cookieConsent: 'https://cookie-consent.iclinicpay.com.br/cookieConsent.js',
  api: {
    client_id: '2',
    client_secret: 'dhGLOnQlLZhDPJmRaNXXJcAOEcDZnqMtm7VGpc5t',
    base_url: 'https://api.iclinicpay.com.br/',
    base_api: 'https://api.iclinicpay.com.br/api/v1/',
    base_api_v2: 'https://api.iclinicpay.com.br/api/v2/',
    base_api_v3: 'https://api.iclinicpay.com.br/api/v3/',
    receipt: 'https://receipt.iclinicpay.com.br/',
  },
  snowplow_collector_url: 'https://br-com-pebmed-main.collector.snplow.net',
  snowplow_app_id: 'medicinae_frontend_organizacao',
  intercom_app_id: 'gl163exs',
  minimum_installment_value: 5,
  featureFlags: {
    modalityScreenAvaliable: false,
    maintenanceAlert: false,
    maintenancePage: false,
  },
};
